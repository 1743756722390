import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./PdfViewer.css"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url,).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({ pdfFile, onLoadSuccess, currentPageNumber}) => {
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const prevPageNumber = () => {
    setPageNum(pageNum - 1);
    currentPageNumber(pageNum - 1);
  }

  const nextPageNumber = () => {
    setPageNum(pageNum + 1);
    currentPageNumber(pageNum + 1);
  }

  // const styles = StyleSheet.create({
  //   section: {
  //     width: 200,
  //     '@media max-width: 400': {
  //       width: 300,
  //     },
  //     '@media orientation: landscape': {
  //       width: 400,
  //     },
  //   }
  // });

  return (
    <div className="PdfWrapper">
      <Document
        file={pdfFile}
        onLoadSuccess={(data) => {
          setTotalPages(data.numPages);
        }}
      >
        <Page
          pageNumber={pageNum + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          onLoadSuccess={(data) => {
            onLoadSuccess(data);
          }}          
        />
      </Document>
      <div className="PageControl-container">
        <button onClick={prevPageNumber} disabled={pageNum - 1 === -1}>
          {"<"}
        </button>
        <div className="PageCounterText">
          Page: {pageNum + 1}/{totalPages}
        </div>
        <button id="RighButton" onClick={nextPageNumber}>
          {">"}
        </button>
      </div>
    </div>
  );
};

export default PdfViewer;
