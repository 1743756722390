import React from "react";

function FirmantesDraggable({ firmantesList, onSelected }) {

  return (
    <>
      {firmantesList.map((item) => {
        return <button key={item.firmanteId} onClick={() => onSelected(item)}>{item.nombre}</button>;
      })}
    </>
  );
}

export default FirmantesDraggable;
