import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import PdfViewer from "./Components/PdfViewer/PdfViewer";
import FirmantesDraggable from "./Components/FirmantesDraggable/FirmantesDraggable";
import DraggableText from "./Components/Draggable/DraggableText";
import { PDFDocument } from "pdf-lib";
import { PuffLoader } from "react-spinners";
import Swal from "sweetalert2";
import BtwLogo from "./assets/btwLogo.png";

function App() {
  const urlBasePetition = process.env.REACT_APP_GDOCNO_URL;
  // eslint-disable-next-line no-useless-escape
  const adjuntoId = parseInt(window.location.href.slice(window.location.href.search(/\=\b/) + 1));
  const [isLoading, setIsLoading] = useState(true);
  const [fatalError, setFaltalError] = useState(false);

  const [firmateDataList, setFirmanteDataList] = useState([]);

  const [firmantesList, setFirmantesList] = useState(null);
  const [pdfBase, setPdfBase] = useState(null);
  const [pdf, setPdf] = useState(null);
  const documentRef = useRef(null);

  const [firmanteSelected, setFirmanteSelected] = useState(null);
  const [draggeableItemVisible, setDraggeableItemVisible] = useState(false);

  const [pageNum, setPageNum] = useState(0);
  const [position, setPosition] = useState(null);
  const [pageDetails, setPageDetails] = useState(null);

  const optionsPostPetitionApiKey = {
    mode: "cors",
    method: "POST",
    headers: {
      "X-API-KEY": process.env.REACT_APP_GDOCNO_APIKEY,
      "Content-type": "application/json; charset=UTF-8",
    },
    body: null,
  };

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const selfCloseApp = () => {
    if (window && window.close) {
      window.self.close();
    } else {
      window.alert("El navegador no permite cerrar la ventana automáticamente, por favor cerrarla y regresar a Gdoc.");
    }
  }

  useEffect(() => {

    const optionsGetPetitionApiKey = {
      mode: "cors",
      method: "GET",
      headers: {
        "X-API-KEY": process.env.REACT_APP_GDOCNO_APIKEY,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    if (adjuntoId === null || adjuntoId === undefined || isNaN(adjuntoId) || adjuntoId === "") {
      setFaltalError(true);
      setIsLoading(false);
      Swal.fire({
        title: "Error",
        html: "Ha ocurrido un error al cargar información del adjunto, revisa que el enlace esté completo. Si consideras que es un error contacta con BTW.",
        icon: "error",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#237e8a",
        allowOutsideClick: false,
      }).then((confirmation) => {
        if (confirmation.isConfirmed) {
          selfCloseApp();
        }
      });
    } else {
      fetch(urlBasePetition + `/FirmaElectronica/GetDataAdjunto?adjuntoFirmaId=${adjuntoId}`, optionsGetPetitionApiKey)
        .then((response) => {
          response.json().then((jsonResponse) => {
            setIsLoading(false);
            if (jsonResponse.success) {
              setFirmantesList(jsonResponse.result.firmanteList);
              setPdfBase("data:application/pdf;base64," + jsonResponse.result.adjuntoBase64);
              setPdf("data:application/pdf;base64," + jsonResponse.result.adjuntoBase64);
            } else {
              Swal.fire({
                title: "Error",
                html: "No se ha podido cargar información del adjunto. Por favor contacta con BTW.",
                icon: "error",
                showConfirmButton: true,
                confirmButtonText: "Aceptar",
                confirmButtonColor: "#237e8a",
                allowOutsideClick: false,
              }).then((confirmation) => {
                if (confirmation.isConfirmed) {
                  selfCloseApp();
                }
              });
            }
          });
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            title: "Error",
            html: "Ha ocurrido un error al conectar con la información del adjunto. Por favor contacta con BTW.",
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: "Aceptar",
            confirmButtonColor: "#237e8a",
            allowOutsideClick: false,
          }).then((confirmation) => {
            if (confirmation.isConfirmed) {
              selfCloseApp();
            }
          });
        });
    }
  }, [adjuntoId, urlBasePetition]);

  const toggleDraggableTextVisibility = (firmanteSelected) => {
    setFirmanteSelected(firmanteSelected);
    setDraggeableItemVisible(true);
  };

  const sendFirmantesDataPosition = () => {
    optionsPostPetitionApiKey.body = JSON.stringify(firmateDataList);
    setIsLoading(true);
    fetch(urlBasePetition + `/FirmaElectronica/RecibeSignPosition?adjuntoId=${adjuntoId}`, optionsPostPetitionApiKey)
      .then((response) => {
        response.json().then((jsonResponse) => {
          setIsLoading(false);
          if (!jsonResponse.success) Swal.fire("Notificación", jsonResponse.message, "warning");
          else {
            Swal.fire({
              title: "Éxito",
              html: "La información de las firmas fue enviada con éxito.",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Aceptar",
              allowOutsideClick: false,
            }).then((confirmation) => {
              if (confirmation.isConfirmed) {
                selfCloseApp();
              }
            });
          }
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: "Error",
          html: "Ha ocurrido un error al enviar la información de los firmantes. Por favor contacta con BTW.",
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#237e8a",
          allowOutsideClick: false,
        }).then((confirmation) => {
          if (confirmation.isConfirmed) {
            selfCloseApp();
          }
        });
      })
      .finally(() => {
        optionsPostPetitionApiKey.body = null;
      });
  };

  return (
    <div className="Main-container">
      <section className="Top-container">
        <img src={BtwLogo} alt="btw-logo" />
      </section>
      <section className="Bot-container">
        {isLoading && !fatalError && (
          <div className="Loading-container">
            <PuffLoader
              color={"whitesmoke"}
              loading={isLoading}
              cssOverride={override}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <h3>Conectando con información de firma electrónica...</h3>
          </div>
        )}

        {!isLoading && !fatalError && (
          <>
            <div className="Options-container">
              <div className="Text-container">
                <h3>Posicionamiento de firmas</h3>
                <p>Aquí puedes difinir donde aparecerán las fimas de cada persona invitada.</p>
                <br></br>
              </div>
              <div className="Buttons-container">
                <FirmantesDraggable firmantesList={firmantesList} onSelected={toggleDraggableTextVisibility} />
              </div>
              <div className="ActionBtn-container">
                <button id="ConfirmBtn" onClick={sendFirmantesDataPosition}>
                  Confirmar
                </button>
                <button
                  id="RestartBtn"
                  onClick={() => {
                    setPdf(pdfBase);
                    setFirmanteDataList([]);
                  }}
                >
                  Reiniciar
                </button>
              </div>
            </div>
            <div className="PdfViewer-container" ref={documentRef}>
              <PdfViewer pdfFile={pdf} onLoadSuccess={(data) => setPageDetails(data)} currentPageNumber={(pageNumber) => setPageNum(pageNumber)} />
              {draggeableItemVisible && (
                <DraggableText
                  initialText={firmanteSelected}
                  onCancel={() => setDraggeableItemVisible(false)}
                  onEnd={setPosition}
                  onSet={async () => {
                    const textDraggElement = document.getElementById("textHolder");
                    const textDraggInfo = textDraggElement.getBoundingClientRect();

                    const { originalHeight, originalWidth } = pageDetails;
                    const scale = originalWidth / documentRef.current.clientWidth;
                    const y = documentRef.current.clientHeight - (position.y - position.offsetY + 45 - documentRef.current.offsetTop);
                    const x = position.x - 130 - position.offsetX - documentRef.current.offsetLeft;

                    // new XY in relation to actual document size
                    const newY = (y * originalHeight) / documentRef.current.clientHeight;
                    const newX = (x * originalWidth) / documentRef.current.clientWidth;

                    const pdfDoc = await PDFDocument.load(pdf);

                    const pages = pdfDoc.getPages();
                    const firstPage = pages[pageNum];

                    firstPage.drawText(firmanteSelected.nombre, {
                      x: newX,
                      y: newY,
                      size: 13 * scale,
                    });

                    const firmanteAdjuntoData = {
                      firmanteAdjuntoId: 0,
                      firmanteId: firmanteSelected.firmanteId,
                      firmaX: newX,
                      firmaY: newY,
                      firmaW: (textDraggInfo.width * originalWidth) / documentRef.current.clientWidth,
                      firmaH: (textDraggInfo.height * originalHeight) / documentRef.current.clientHeight,
                      numPagina: pageNum,
                    };

                    setFirmanteDataList([...firmateDataList, firmanteAdjuntoData]);
                    const pdfBytes = await pdfDoc.saveAsBase64();
                    setPdf("data:application/pdf;base64," + pdfBytes);
                    setPosition(null);
                    setDraggeableItemVisible(false);
                    setFirmanteSelected(null);
                  }}
                />
              )}
            </div>
          </>
        )}
      </section>
    </div>
  );
}

export default App;
