import { useState, useEffect, useRef } from "react";
import "./DraggableText.css";
import Draggable from "react-draggable";
import { FaCheck, FaTimes } from "react-icons/fa";

export default function DraggableText({ initialText, onEnd, onSet, onCancel }) {
  const [text, setText] = useState("Nombre");
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialText) {
      setText(initialText.nombre)
    } else {
      inputRef.current.focus();
      inputRef.current.select()
    }
  }, [initialText])

  return (
    <Draggable onStop={onEnd}>
      <div className="Draggable-container" >
        <div className="Controls-container" >
          <div className="SmallButton" onClick={onSet}>
            <FaCheck color={"green"} />
          </div>
          <div className="SmallButton" onClick={onCancel}>
            <FaTimes color={"#ff0000e0"} />
          </div>
        </div>
        <input id="textHolder" ref={inputRef} value={text} placeholder={"Nombre firmante"} onChange={(e) => setText(e.target.value)} onClick={(e) => {e.preventDefault();}} readOnly/>
      </div>
    </Draggable>
  );
}
